import React from "react"

import classNames from "classnames"
import DefaultLayout from "../../layout/default"

import CtaForm from "../../pagesSections/index/CtaForm"

import pageStyles from "../../styles/_V2/pagesStyles/styles.module.scss"
import styles from "../styles/_V2/docs.module.scss"
import AdaptiveLink from "../../components/AdaptiveLink"
import { pagesLinks } from "../../pages-data/common/links"

export default function PagePrivacyPolicy() {
	const metaTags = [
		// {
		//   name: 'keywords',
		//   content: 'Автоматизация ресторана, автоматизация кафе, автоматизация бара, автоматизация столовой, ipad pos, касса на ipad, калькуляция, расчет себестоимости, технологическая карта, российская система автоматизации',
		// },
		{
			name: "description",
			content: "Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.",
		},
		{
			property: "og:url",
			content: "https://quickresto.ru/licenseAgreement/annex3",
		},
		{
			property: "og:title",
			content: "Лицензионный договор на право использования системы автоматизации Quick Resto",
		},
		{
			property: "og:description",
			content: "Лицензионный договор является офертой ООО «Квик Ресто» — система автоматизации предприятий общественного питания Quick Resto — программа для полноценного складского учёта в общепите онлайн, удобный кассовый терминал на планшете, CRM и настраиваемая система отчетов.",
		},
		{
			property: "og:type",
			content: "website",
		},
	]

	return (
		<DefaultLayout
			title="Приложение № 3"
			metaTags={metaTags}
			mainWrapperClassName={classNames(
				pageStyles.pageWrapper,
				pageStyles.pageGray,
				styles.docs,
			)}
			linkCanonical="https://quickresto.ru/licenseAgreement/annex3/"
			footerClassName={pageStyles.pageSection}
		>
			<section
				className={classNames(styles.docs__container, pageStyles.pageSection)}
			>
				<div className={styles.docs__content}>
					<h1 className={styles.bigMb}>
						Специальные условия Лицензии на Опциональную программу - Сервис «Лояльность.Карты»
						входящую в пакет Опциональных программ для ЭВМ «Лояльность.360»
					</h1>

					<div>
						<p>
							Приложение № 3
							к Лицензионному договору на право использования
							программы для ЭВМ «Система автоматизации предприятий
							общественного питания «Quick Resto»» и иных
							программных продуктов ООО «Квик Ресто»
						</p>

					</div>

					<div>
						<p />

						<p>1. Настоящее Приложение, как и Лицензионный договор, является офертой <br />ООО&nbsp;&laquo;Квик
							Ресто&raquo;&nbsp;(ОГРН 5137746099046, ИНН&nbsp;7726734798, КПП 771401001, 125167 г. Москва, Ленинградский
							пр. 36, стр. 30, пом. 96), именуемого в дальнейшем Лицензиар, Пользователю, именуемому в дальнейшем
							Лицензиат или Оператор персональных данных. <br />2. Под офертой согласно настоящему Приложению № 3
							подразумевается предложение Лицензиара адресованное, имеющему лицензию на основную Программу Лицензиату,
							приобрести на срок не превышающий срок лицензии основного Программного продукта, за дополнительное
							вознаграждение Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в частности входящую в
							указанный пакет Опциональную программу Сервис &laquo;Лояльность.Карты&raquo; на основании простой
							(неисключительной) лицензии на результат интеллектуальной деятельности ООО &laquo;Квик
							Ресто&raquo; &ndash; разработанную Лицензиаром программу для ЭВМ
							Сервис &laquo;Лояльность.Карты&raquo; (далее &ndash; Сервис &laquo;Лояльность.Карты&raquo;), которую
							Лицензиар в соответствии с требованиями Лицензиата визуально адаптирует под его деятельность и которая
							предназначается для использования клиентами Лицензиата (потребителями товаров, работ, услуг Лицензиата).
						</p>
						<p>Сервис &laquo;Лояльность.Карты&raquo; является информационной панелью, которая позволяет Лицензиату как
							предприятию или сети предприятий общественного питания самостоятельно формировать и редактировать для
							клиентов Лицензиата электронные карты лояльности, вносить в указанные карты необходимые данные, начислять
							различные скидки и бонусы, в том числе в зависимости от заказов клиента.</p>
						<p>Простая (неисключительная) лицензия на результат интеллектуальной деятельности ООО &laquo;Квик
							Ресто&raquo; &ndash; разработанную Лицензиаром программу для ЭВМ
							Сервис &laquo;Лояльности.Карты&raquo; реализуется в пакете опциональных программ для
							ЭВМ &laquo;Лояльность.360&raquo; или отдельно от пакета, исключительно пользователям имеющим Лицензию на
							основную программу и исключительно на срок не более чем срок лицензии основного программного продукта.</p>
						<p>Акцепт на указанную в п.2 настоящего Приложения оферту возможен при одновременном соблюдении 3
							условий:</p>
						<p>1) Лицензиат приобретающий Лицензию на Сервис &laquo;Лояльности.Карты&raquo; в пакете Опциональных
							программ для ЭВМ &laquo;Лояльность.360&raquo; или отдельно Сервис &laquo;Лояльности.Карты&raquo;, должен
							иметь Лицензию на основной программный продукт (Программу)<br />2) Оплата вознаграждения за пакет
							опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; или отдельно за
							Сервис &laquo;Лояльности.Карты&raquo; по счету, выставленному Лицензиаром Лицензиату в соответствии с
							Тарифным планом, выбранным Лицензиатом<br />3) Лицензиат приобретает Лицензию на
							Сервис &laquo;Лояльности.Карты&raquo; в Пакете Опциональных программ для
							ЭВМ &laquo;Лояльность.360&raquo; или отдельно Сервис &laquo;Лояльности.Карты&raquo; на срок не более чем
							срок действующей лицензии на основной программный продукт.</p>
						<p>При не соблюдении любого из вышеуказанных пунктов &ndash; оферта не считается акцептованной</p>
						<p>2.1 Порядок предоставления Сервиса &laquo;Лояльность.Карты&raquo;:</p>
						<p>Лицензиат самостоятельно настраивает дизайн &laquo;карты лояльности&raquo; (цвет фона, название и логотип
							заведения, иконку для площадок Apple Wallet и Google Wallet), вносит правовые документы и настраивает
							правила бонусной программы, в соответствии с правилами размещения Площадок данной информации, и отправляет
							Лицензиару запрос на модерацию (проверку данных) посредством кнопки &laquo;отправить на проверку&raquo; в
							облачном бэк-офисе.</p>
						<p>Лицензиар проводит проверку заполненных данных на предмет размещения запрещенных материалов (изображения
							алкогольной продукции, изображения и текстов о продуктах курения &ndash; табачной продукции и иных
							продуктов для курения, в том числе кальянов, вейпов и сопутствующих товаров, изображения или текстов
							сексуального характера, порнографии). Лицензиар подтверждает или отклоняет запрос на проверку в
							соответствии с требованиями заполнения данных.</p>
						<p>Проверка/модерация информации/контента производится Лицензиаром только в части, размещенной на серверах
							Лицензиара и носит рекомендательный характер по причине того, что карты лояльности заполняются и
							настраиваются Лицензиатом самостоятельно под собственные нужды и концепцию заведения - Лицензиат должен
							самостоятельно ознакомиться с правилами Apple и Google предъявляемым к картам лояльности и самостоятельно
							удостовериться в факте нарушения/не нарушения правил площадок, Лицензиар не отвечает за убытки и упущенную
							выгоду в случае блокировки карты лояльности не соответствующих правилам Apple и Google.</p>
						<p>Модерация/проверка контента размещенного по ссылкам указанным Лицензиатом не проводится по причине
							отсутствия доступа к модерации интернет-ресурса Лицензиата, а также по причине его изменяемости
							(динамичности наполнения), иными словами информация размещенная Лицензиатом по ссылкам, то есть не на
							серверах Лицензиата может меняться Лицензиаром в одностороннем порядке в любой момент времени без
							обязательного уведомления Лицензиара, таким образом Лицензиат самостоятельно несет ответственность в
							случае блокировки карты лояльности не соответствующих правилам Apple и Google</p>
						<p>После прохождения модерации у Лицензиата открывается доступ к формированию ссылки
							(https://облако.quickresto.ru/cpr/registration/) с анкетой для конечных пользователей в
							Сервисе &laquo;Лояльность.Карты&raquo;. Лицензиат вправе размещать данную ссылку на любых ресурсах и в
							любом виде, в том числе в виде QR-кода.</p>
						<p>Клиент Лицензиата, как предприятия или сети предприятий общественного питания, вправе зарегистрироваться
							в Сервисе &laquo;Лояльность.Карты&raquo;, заполнив анкету, указав свои Имя, Фамилию, телефон и дату
							рождения, и добавить карту в один из сервисов &ndash; Apple Wallet или Google Wallet. Регистрация в
							Сервисе &laquo;Лояльность.Карты&raquo; позволит клиенту лицензиата участвовать в программах лояльности
							Лицензиата &ndash; бонусных программах и иных, в том числе видеть в режиме онлайн количество доступных
							бонусов, статус клиента в бонусной программе и использовать QR-код/Штрих-код для авторизации в заведении
							Лицензиата.<br />Сервис &laquo;Лояльность.Карты&raquo; является универсальным программным продуктом и
							предоставляется пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;. Лицензиар не участвует в
							формировании и редактировании электронных карт клиентов Лицензиата, внесению в указанные карты необходимых
							данных и начислению на эти карты скидок и бонусов.<br />3. Лицензиар гарантирует Лицензиату, что он
							является законным правообладателем Программы для ЭВМ Сервис управления электронными
							картами &laquo;Лояльность. Карты&raquo;.</p>
						<p>4. По итогам каждого периода использования прав на Пакет опциональных программ для
							ЭВМ &laquo;Лояльность.360&raquo; в частности входящую в указанный пакет Опциональную программу
							Сервис &laquo;Лояльность.Карты&raquo; или отдельно от указанного Пакета, Лицензиар направляет на
							электронную почту, указанную Лицензиатом, акт исполнения обязательств по настоящему Договору. При
							отсутствии у Лицензиата в течение 3 (трех) календарных дней с момента направления ему данного акта и
							отсутствия мотивированных возражений, данный акт считается согласованным и подписанным Лицензиатом, а
							услуги принятыми без претензий к качеству и срокам.</p>
						<p>Срок действия Лицензии на Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в частности
							входящую в указанный пакет Опциональную программу Сервис &laquo;Лояльность.Карты&raquo; и срок доступа к
							Опциональной программе Сервис &laquo;Лояльность.Карты&raquo; входящей Пакет опциональных программ для
							ЭВМ &laquo;Лояльность.360&raquo;, определяются в соответствии с Разделом 2 и Разделом 4 Лицензионного
							договора на право использования программы для ЭВМ &laquo;Система автоматизации предприятий общественного
							питания &laquo;Quick Resto&raquo; и иных программных продуктов ООО &laquo;Квик Ресто&raquo;</p>
						<p>&nbsp;</p>
						<p>5. В дальнейшем продление Лицензии на Пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в
							частности входящую в указанный пакет Опциональную программу Сервис &laquo;Лояльность.Карты&raquo; и оплата
							вознаграждения за предоставление Лицензии на Пакет опциональных программ для
							ЭВМ &laquo;Лояльность.360&raquo; в частности входящую в указанный пакет Опциональную программу
							Сервис &laquo;Лояльность.Карты&raquo; осуществляется на условиях определяются в соответствии с п.2.2,2,4 и
							Разделом 2 и Разделом 4 Лицензионного договора на право использования программы для ЭВМ &laquo;Система
							автоматизации предприятий общественного питания &laquo;Quick Resto&raquo; и иных программных продуктов
							ООО &laquo;Квик Ресто&raquo; (далее &ndash; &laquo;Договор&raquo; или &laquo;Лицензионный договор&raquo;).<br />6.
							Карта лояльности Лицензиата размещается Лицензиаром под учетной записью Лицензиара в сервисе Google,
							ссылка на место размещения, которого предоставляется Лицензиату. Лицензиат самостоятельно организовывает
							формирование электронных карт лояльности для своих клиентов. Сформированные электронные карты лояльности
							для их применения во взаимоотношениях Лицензиата со своими клиентами, подлежат добавлению последними в
							системы электронных платежей: Apple Wallet или Google Wallet, в зависимости от вида мобильных устройств,
							используемых клиентами Лицензиата.<br />Для создания пользователем карты лояльности необходимо
							подтверждение номера телефона, которое осуществляется через смс-сообщение или push-уведомление и позволяет
							идентифицировать пользователю по его абонентскому номеру. Эта авторизация осуществляется исключительно
							Лицензиаром, идентично опциональной программе Брендированное приложение White Label (Приложение №1 к
							Лицензионному договору).<br />Электронные карты форматов Apple Wallet и Google Wallet размещаются на
							инфраструктуре, принадлежащей компаниям Apple и Google и подчиняются правилам этих площадок.
							Сервис &laquo;Лояльность.Карты&raquo; &mdash; это сервис для предоставления доступа, настройки и
							обслуживания электронных карт в системе Apple Wallet и Google Wallet.<br />7. Во всем ином, не
							предусмотренном настоящим Приложением № 3, применяются положения Лицензионного договора, а также положения
							действующего законодательства Российской Федерации.</p>
						<p>8. Краткое описание Программы для ЭВМ Сервис управления электронными картами &laquo;Лояльность.
							Карты&raquo; и её возможностей, а также преимуществ содержится по следующей интернет ссылке:
							https://quickresto.ru/passkit/.</p>
						<p>9. Лицензиат подтверждает, что он:<br />&middot;&nbsp;перед заключением настоящего Приложения № 3 в
							полном объеме ознакомлен с функционалом (возможностями)
							Сервиса &laquo;Лояльность.Карты&raquo;;<br />&middot; перед заключением настоящего Приложения № 3 в полном
							объеме ознакомлен с текстом Лицензионного договора на право использования программы для ЭВМ &laquo;Система
							автоматизации предприятий общественного питания &laquo;Quick Resto&raquo;&raquo; и иных программных
							продуктов ООО &laquo;Квик Ресто&raquo;, в котором содержатся все основные условия предоставления лицензий
							на программные продукты ООО &laquo;Квик Ресто&raquo; и который содержится по следующей интернет ссылке:
							https://quickresto.ru/licenseAgreement/;<br />- соглашается с тем, что:<br />С 01.09.2024 г. новые
							Лицензиаты (новые клиенты) не имеют возможность приобрести лицензию на пакет Опциональных программ для
							ЭВМ &laquo;Лояльность.360&raquo; без приобретения Программы.<br />Действующие Лицензиаты, имеющие
							оплаченные/приобретенные Лицензии на опциональные программы на 01.09.2024 г. (до введения пакета
							Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo;) пользуются лицензиями до момента окончания
							своих действующих лицензий на опциональные программы, после окончания срока таких лицензий возможность
							приобрести/продлить опциональные программы без покупки основной программы не допускается. <br />С
							01.09.2024 г. срок действия лицензии на пакет Опциональных программ для
							ЭВМ &laquo;Лояльность.360&raquo; не может быть больше, чем срок действия лицензии на основную Программу,
							указанное правило не распространяется на уже действующие лицензии.<br />- Предупрежден о том, что без
							приобретения Лицензии на основную Программу/в случае окончания лицензии на основную Программу,
							опциональные программы будут работать в ограниченном режиме, с ограниченным функционалом/нестабильно и
							Лицензиар не несет ответственности за такие действия, так как это не зависит от действий Лицензиара, а
							связано с техническими особенностями Программы и Опциональных программных продуктов<br />- осознает, что
							Программа является основным продуктом Лицензиара, без которого использование любой опциональной программы
							из пакета Опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; в полном объеме будет функционально
							ограничено, в виду того, что опциональные программы являются вторичными (дополнительными) к Программе и
							внедряются в основную Программу, позволяя расширить ее функционал, а именно в панель управления
							Программой. В отсутствие приобретенной лицензии на основную Программу, основные разделы/модули в панели не
							будут доступны/будут работать нестабильно, что повлияет на работу Опциональных
							программ.<br />&middot;&nbsp;осознаёт, что Сервис &laquo;Лояльность.Карты&raquo; является универсальным
							программным продуктом и предоставляется пользователям (Лицензиатам) в том виде &laquo;как есть&raquo;, за
							следующим исключением: Сервис &laquo;Лояльность.Карты&raquo; визуально адаптируется под деятельность
							Лицензиата, без изменения функционала Сервиса &laquo;Лояльность.Карты&raquo;, иные адаптации оцениваются
							человеко-часами и оплачиваются дополнительно;<br />- Лицензиар не несёт ответственность за удаление или
							ограничение доступа к Карте лояльности по независящим от него причинам (Санкции, размещения Лицензиатам
							материалов нарушающих правила площадок, иные ограничения платформ).<br />- осознает, что при неуплате
							продления Лицензии отдельно / на пакет опциональных программ для ЭВМ &laquo;Лояльность.360&raquo; куда
							входит Сервис &laquo;Лояльность.Карты&raquo;, Лицензиар вправе отключить, а затем удалить Карту Лояльности
							Лицензиата из Apple Wallet и Google Wallet и отключить функционал настройки в облачном бэк-офисе
							Лицензиата;</p>
						<p>- осознает, что функционирование карт лояльности возможно только при наличии оплаченной лицензии на
							систему автоматизации Quick Resto, а также что карты лояльности, как и система лояльности не будут
							обновляться и работать без рабочего облака клиента. Как и не будет работать система лояльности.</p>
						<p>&middot; осознаёт, что, используя функциональные возможности Сервиса &laquo;Лояльность.Карты&raquo;,
							Лицензиат самостоятельно формирует и редактирует электронные карты лояльности для своих клиентов, а также
							вносит в указанные карты необходимые данные, начисляет на эти карты различные скидки и бонусы, в том числе
							в зависимости от заказов клиента блюд и напитков;</p>
						<p>- Ознакомлен с политикой использования Apple Wallet и Google Wallet и осознает перечень запрещенных
							изображений/символик/наименований, а также какого вида/типа заведения Лицензиата использующие карты
							лояльности могут быть заблокированы за нарушение правил Apple и Google Wallet (Google Wallet Acceptable
							Policy) &ndash; в случае блокировки аккаунт по причине нарушения Лицензиатом правил Google Wallet и иных
							правил площадки Google Лицензиар не несет ответственности и не производит возврат денежных средств, в
							случае блокировки аккаунта Лицензиата Лицензиар не отвечает ни за какие убытки и упущенную выгоду.</p>
						<p>-&nbsp;заключает настоящее Приложение № 3, так как убедился в том, что
							Сервис &laquo;Лояльность.Карты&raquo; в полной мере соответствует потребностям и ожиданиям Лицензиата.</p>
						<p>- Самостоятельно несет расходы на авторизацию пользователей карты лояльности на основании п. 13.4
							Лицензионного соглашения.</p>
						<p>- Не несет расходы на хранение пользовательских данных Лицензиата, а также оплату сертификата Apple
							Wallet и Google Wallet &ndash; только на период действия оплаченной Лицензии.<br />10. В случае блокировки
							карты лояльности Лицензиата, Лицензиар за дополнительную плату производит повторное создание карты
							лояльности для Лицензиата</p>

					</div>
				</div>
			</section>

			<CtaForm
				isFry={false}
				className={pageStyles.pageSection}
				isWhiteBackground
			/>
		</DefaultLayout>
	)
}
